// extracted by mini-css-extract-plugin
export var storyRowWrapperCenter = "z_vf d_bD";
export var storyRowWrapper = "z_hx d_hx d_bK";
export var storyLeftWrapper = "z_vg d_bz d_bP";
export var storyWrapperFull = "z_vh d_cD";
export var storyWrapperFullLeft = "z_pl d_cD d_bz d_bP d_bD";
export var contentWrapper = "z_mv d_hy";
export var storyLeftWrapperCenter = "z_vj d_hz d_bz d_bD d_bP d_bJ";
export var storyRightWrapperCenter = "z_vk d_hF";
export var storyHeader = "z_vl d_hD d_w d_cs";
export var storyHeaderCenter = "z_hC d_hC d_w d_cs d_dw d_by";
export var storyParagraphCenter = "z_hB d_hB d_by d_dw";
export var storyBtnWrapper = "z_vm d_d2 d_d1 d_w d_bz d_bG";
export var storyBtnWrapperCenter = "z_hJ d_hJ d_d1 d_w d_bz d_bD";
export var imageWrapper = "z_vn d_fg d_Z";
export var imageWrapperFull = "z_vp d_w d_H d_bf d_Z";
export var SubtitleSmall = "z_qd r_qd r_rk r_rw";
export var SubtitleNormal = "z_qf r_qf r_rk r_rx";
export var SubtitleLarge = "z_qg r_qg r_rk r_ry";
export var textLeft = "z_dv";
export var textCenter = "z_dw";
export var textRight = "z_dx";