// extracted by mini-css-extract-plugin
export var socialContainer = "y_t1";
export var instagramLink = "y_t2";
export var socialWrapperLeft = "y_nX d_dC d_Z d_bz d_bJ d_bp d_bG";
export var socialContentContainer = "y_t3";
export var socialContentContainerFull = "y_t4";
export var instagramContainer = "y_t5";
export var twitterContainer = "y_t6";
export var facebookContainer = "y_t7";
export var socialErrorContainer = "y_t8";
export var facebookContainerWide = "y_t9";
export var twitterContainerWide = "y_vb";
export var socialParagraphCenter = "y_vc";
export var instaWrapper = "y_vd";
export var SubtitleSmall = "y_qd r_qd r_rk r_rw";
export var SubtitleNormal = "y_qf r_qf r_rk r_rx";
export var SubtitleLarge = "y_qg r_qg r_rk r_ry";
export var textLeft = "y_dv";
export var textCenter = "y_dw";
export var textRight = "y_dx";