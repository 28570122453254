// extracted by mini-css-extract-plugin
export var heroHeaderLeft = "p_qS d_gv d_cs";
export var heroHeaderCenter = "p_gw d_gw d_cs d_dw";
export var heroHeaderRight = "p_gx d_gx d_cs d_dx";
export var heroParagraphLeft = "p_qT d_gr d_cw";
export var heroParagraphCenter = "p_gs d_gs d_cw d_dw";
export var heroParagraphRight = "p_gt d_gt d_cw d_dx";
export var heroBtnWrapperLeft = "p_qV d_d2 d_d1 d_w d_bz d_bG";
export var heroBtnWrapperCenter = "p_qW d_d3 d_d1 d_w d_bz d_bD";
export var heroBtnWrapperRight = "p_qX d_d4 d_d1 d_w d_bz d_bH";
export var overlayBtnWrapper = "p_qY d_gq d_0 d_7 d_8 d_9 d_bn d_cc";
export var design4 = "p_qZ d_gp d_0 d_7 d_8 d_bn";
export var heroExceptionSmall = "p_q0 r_q0";
export var heroExceptionNormal = "p_q1 r_q1";
export var heroExceptionLarge = "p_q2 r_q2";
export var Title1Small = "p_q3 r_q3 r_rk r_rl";
export var Title1Normal = "p_q4 r_q4 r_rk r_rm";
export var Title1Large = "p_q5 r_q5 r_rk r_rn";
export var BodySmall = "p_q6 r_q6 r_rk r_rD";
export var BodyNormal = "p_q7 r_q7 r_rk r_rF";
export var BodyLarge = "p_q8 r_q8 r_rk r_rG";