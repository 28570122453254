// extracted by mini-css-extract-plugin
export var alignLeft = "q_qh d_bG";
export var alignCenter = "q_bP d_bD";
export var alignRight = "q_qj d_bH";
export var textAlignLeft = "q_q9";
export var textAlignCenter = "q_rb";
export var textAlignRight = "q_rc";
export var hoursInnerWrapperAlt = "q_rd d_fb d_P d_Z d_bz d_bD d_bP d_dj";
export var hoursInnerWrapperLeft = "q_rf d_fc d_fb d_P d_Z d_bz d_bD d_bP d_b5 d_dj";
export var hoursText = "q_rg d_dw";
export var titleMargin = "q_rh d_cw";
export var hoursInnerInnerWrapper = "q_rj d_cz";