// extracted by mini-css-extract-plugin
export var lbContainerOuter = "C_vx";
export var lbContainerInner = "C_vy";
export var movingForwards = "C_vz";
export var movingForwardsOther = "C_vB";
export var movingBackwards = "C_vC";
export var movingBackwardsOther = "C_vD";
export var lbImage = "C_vF";
export var lbOtherImage = "C_vG";
export var prevButton = "C_vH";
export var nextButton = "C_vJ";
export var closing = "C_vK";
export var appear = "C_vL";